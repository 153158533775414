<script setup>
import { watch } from 'vue'

import PostMenu from '@/Pages/Post/Partials/PostMenu.vue'
import { useStorage } from '@vueuse/core'
import { usePostStore } from '@/Stores/PostStore'
import PostVideo from '@/Pages/Post/Partials/PostVideo.vue'
import { useSwiperSlide } from 'swiper/vue'
import PostControls from '@/Pages/Post/Partials/PostControls.vue'
import PostContent from '@/Pages/Post/Partials/PostContent.vue'
import PostImage from '@/Pages/Post/Partials/PostImage.vue'
import { useAnalyticsStore } from '@/Stores/AnalyticsStore'

const props = defineProps({
	post: {type: Object, required: true},
	active: Boolean
});

const analyticsStore = useAnalyticsStore();
const swiperSlide = useSwiperSlide();
const postStore = usePostStore();
const emit = defineEmits(['videoEnded']);

watch(() => swiperSlide.value, (slide) => {

	// actions to perform when the slide becomes active
	if (slide.isActive) {

		postStore.setCurrentPost(props.post)

		triggerEvents()

		saveToHistory()
	}

}, {immediate: true, deep: true})

/**
 * Save post to history collection
 */
const saveToHistory = () => {
	let posts = useStorage('history', []).value || [];

	if (!posts.includes(props.post.uuid)) {

		// limit history to 100 posts
		// remove the oldest post if needed
		if (posts.length > 100) {
			posts.shift();
		}

		posts.push(props.post.uuid)
		useStorage('history', posts)
	}
}

/**
 *
 */
const triggerEvents = () => {

	let post_path = props.post.url.replace(/^https?:\/\/[^/]+/, '')

	analyticsStore.sendAnalyticsEvent('post_view', {
		post_id: props.post.uuid,
		post_path: post_path,
		post_title: props.post.title
	});
}
</script>

<template>
	<div class="relative w-full h-full flex-1 rounded-lg">

		<div class="absolute bottom-0 flex flex-col justify-between w-full">
			<div class="flex space-x-6 items-end md:p-8 p-4 w-full z-10 bg-gradient-to-t from-black to-transparent">
				<PostContent/>
				<PostControls/>
			</div>
		</div>

		<PostVideo v-if="post.type === 'video'" :poster="post.media_poster" :sources="post.media"
		           @ended="emit('videoEnded')"/>

		<PostImage v-else/>

		<PostMenu/>
	</div>
</template>

